import React from 'react'
import { Link } from 'gatsby'

import BlogSearch from './BlogSearch'
import './PostCategoriesNav.css'

const PostCategoriesNav = ({ categories, enableSearch }) => (
  <div className="PostCategoriesNav">
    <Link
      className="NavLink button-category"
      exact="true"
      to={`/novinky-blog/`}
    >
      Vše
    </Link>
    {categories.map((category, index) => (
      <Link
        exact="true"
        className="NavLink button-category"
        key={'category.title' + index}
        to={category.slug}
      >
        {category.title}
      </Link>
    ))}

    {enableSearch && <BlogSearch />}
  </div>
)

export default PostCategoriesNav
