import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

export default ({ pageCount }) => {
  const [searchValue, setsearchValue] = useState('')
  const searchIt = location => {
    const search = qs.stringify(searchValue)
    const url = location.href
      .replace(location.origin, '')
      .replace(location.search, '')

    navigate(`${url}?${search}`)
  }

  /*
  const removeLast = location => {
    const sliced = searchValue.s.slice(0, -1)
    let slicedObject = {}
    slicedObject.s = sliced
    const search = qs.stringify(slicedObject)
    const url = location.href
      .replace(location.origin, '')
      .replace(location.search, '')

    navigate(`${url}?${search}`)
  }*/

  return (
    <Location>
      {({ location }) => {
        let search = qs.parse(location.search.replace('?', ''))

        return (
          <div className="PostCategoriesNav--Input">
            <input
              type="text"
              accept-charset="character_set"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  searchIt(location)
                }
              }}
              value={searchValue.s || ''}
              placeholder="Vyhledat..."
              onChange={e => {
                let search = {}
                search.s = e.target.value
                setsearchValue(search)
              }}
            />
            <div
              onClick={() => searchIt(location)}
              className="button-primary PostCategoriesNav--Button"
            >
              Vyhledat
            </div>
          </div>
        )
      }}
    </Location>
  )
}
